import { EnsuranceBaseInfo } from '@/actions/types/EnsuranceOffer';
import { FilteredStartValue } from '../actions/types/FilteredStartValue';
import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { CustomProduct, CustomProductNames } from './customProducts/customProducts.types';


export type Devicemode = {
  name: string;
  insuranceObjectOwnerName?: string;
  insuranceObjectName?: string;
  selectable: boolean | null;
  virtual: boolean | null;
  default: boolean | null;
  gewerblichSelectable: boolean | null;
  devkey: string;
  infotext: string;
  icon: string;
};

export type HausratsGesellschaftDisplay = {
  Value: string;
  Text: string;
};

export type FrontendRedirectEntry = {
  displayName: string;
  instanceRootUrl: string;
}

export type EnsInstanceMode = 'bike' | 'animal' | 'photovoltaik' | 'kv' | 'wohnen' | 'person';

const defaultState = {
  cities: [],
  frontendInstanceRedirect: [] as FrontendRedirectEntry[],
  compareTables: {} as Record<string, { id: string; displayName: string; devicemodes: string[] }>,
  ens_public_link_to_calculator: '',
  gesellschaften: [{ Text: 'Wird geladen...', Value: '' }] as HausratsGesellschaftDisplay[],
  devicemodes: [] as Devicemode[],
  inspectionvoucher_show_cta: '',
  inspectionvoucher_infotext: '',
  inspectionvoucher_agbText: '',
  requiredFieldsLoaded: false,
  customquestions: [] as DynamicEntryQuestionType[],
  premiumservicePrice: null,
  compareTariffLimit: 3,
  userData_filteredStartValues: [] as FilteredStartValue[],
  ens_hide_frontend_filterbox: '',
  ens_fb_tracking_id: '',
  ens_erstinformation_url: '',
  ens_rechtsbelehrung_url: '',
  ens_instance_mode: null as EnsInstanceMode | null,
  ens_impressum_url: null,
  ens_datenschutz_url: null,
  ens_byweredby: null,
  ens_byweredby_url: null,
  ens_styling: null,
  ens_comparemode_show_entryquestions: null as null | '0' | '1',
  ens_save_entrquestions: null as null | '' | '1',
  ens_agb_url_IV: '',
  ens_agb_url_Premiumservice: '',
  ens_widerruf_Premiumservice: '',
  ens_rechtsbelehrung_Premiumservice: '',
  ens_instance_area: '',
  ens_fallback_vouchercode: '',

  ens_stripe_public_apikey: '',
};

export type TypesEnsFields = typeof defaultState;

export type EnsFieldsReducer = typeof defaultState;

export default function (state = defaultState, action): EnsFieldsReducer {
  if (action.type === 'ENS_FIELD_LOADED') {
    return { ...state, [action.name]: action.value };
  } else if (action.type === 'ENS_FIELDS_LOADED') {
    return {
      ...state,
      ...action.values,
      ...(action.mode === 'default' ? { requiredFieldsLoaded: true } : {}),
    };
  } else if (action.type === 'ENS_PLZ_CITIES_LOADED') {
    return { ...state, cities: action.value };
  }

  return state;
}

export const hasEnsfieldFlag = (value: any) => !!value && value !== '0' && value !== 'false' && value !== 'no';
